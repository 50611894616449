import React , { useState , useEffect } from 'react';
import BlogsContainer from '../../containers/BlogsContainer';
import PageBanner from '../../containers/PageBanner';
import axios from 'axios';

const Blog = () => {
    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com/news/${lang}/`)
        .then(response => {
            setData(response.data)
        })
    },[])
    return (
        <>
            <PageBanner pageTitle='Pages' title="News" activePage="News"></PageBanner>
            <main className="wrapper">
                <BlogsContainer data={data} />
            </main>
        </>
    );
};

export default Blog;