import React from 'react';
import BlogCard from '../components/BlogCard';
import i18next from 'i18next';

const Blogs = (props) => {
    const { data } = props;
    if (!data || !data.news) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { news } = data;
    return (
        <section className="blog pt-lg bg-dark-100">
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">{i18next.t("Latest")}</h2>
            </div>
            <div className="container">
                <div className="section-header text-center has_line">
                    <h2 className="text-white">{i18next.t("LATEST News")}</h2>
                </div>
                
                <div className="row">
                    {news.map((items , index) => {
                        return(
                            <BlogCard key={index} img={`https://backend.alghadeerrealestate.com/${items.image}`} title={items.title} text={items.subtitle.substring(0, 200)} date={items.created_at} dateTime='2022-10-20' postCount={items.id} index={index} data={data.news} />
                        )
                    })}
                </div>
            </div>

        </section>
    );
};

export default Blogs;