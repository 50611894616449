import React , { useState } from 'react';
import axios from 'axios';
import i18next from 'i18next';

const Form = () => {

    const [data , setData] = useState({
        name :"",
        phone : "",
        email : "",
        message : "",
    });

      // phoneID
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');

    const handlePhoneChange = (event) => {
    const phoneRegex = /^(\+?\d{1,3}[\s-]?)?\d{10}$/;
    const value = event.target.value;

    setPhone(value);

    if (!phoneRegex.test(value)) {
      setError('تأكد من صحة رقم الهاتف.');
    } else {
      setError('');
    }
  };

    const handlerChange = (e) => {
        const {name , value} = e.target;
        setData((prev) => {
          return {...prev, [name] : value}
        })
    }
    
    const handlerSubmit = (e) => {
        e.preventDefault();  
          const newData = {
            name : data.name,
            phone : data.phone,
            email : data.email,
            message : data.message,
        }
        axios
          .post("https://backend.alghadeerrealestate.com/contact/contact/" , newData)
          .then(res => window.location.reload() )
    }
    return (
        <div className="home_contact">
            <form action="/" method="post" onSubmit={handlerSubmit}>
                <input className="form-control form-control-lg" name="name" id="name" type="text" placeholder={i18next.t("Your Name*")} required aria-label=".form-control-lg example" onChange={handlerChange} />
                <input className="form-control form-control-lg" name="phone" id="phone" type="number" placeholder={i18next.t("Your Phone No")} aria-label=".form-control-lg example" onChange={(e) => {handlerChange(e) ; handlePhoneChange(e)}} />
                <div style={{ color: 'red' }}>{error}</div>
                <input className="form-control form-control-lg" name="email" id="email" type="email" placeholder={i18next.t("Your Email*")} required aria-label=".form-control-lg example" onChange={handlerChange} />
                <textarea className="form-control pt-4" name="message" id="message" placeholder={i18next.t("Your Message")} rows="3" onChange={handlerChange}></textarea>
                <div className="btn_group">
                    <button type="submit" className="btn olive">{i18next.t("Send Mail")}</button>
                </div>
            </form>
        </div>

    );
};

export default Form;