import $ from 'jquery';
import React, { useEffect } from 'react';
import SectionGridLines from '../../../components/SectionGridLines';
import i18next from 'i18next';

const Footer = () => {

    useEffect(() => {
        // Totop Button
        $('.totop a').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

        // Hide header on scroll down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }
            lastScroll = currentScroll;
        });

    }, []);

    return (
        <>
            <footer className="footer bg-dark-200 box_padding">
                <div className="footer_inner bg-black">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-4 col-md-2 col-sm-2">
                                <div className="section-header">
                                    <h2>{i18next.t('Contact')}</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="communication">
                                    <div className='info_body'>
                                        <div className="footer_logo">
                                            <a href="/" className="light_logo"><img style={{"maxWidth" : "80px"}} src="images/lightlogo.svg" alt="logo" /></a>
                                        </div>
                                    </div>
                                    <div className="info_body">
                                        <h5>{i18next.t('Contact Info')}</h5>
                                        <p>6035</p>
                                        <p>info@alghadeerrealestate.com</p>
                                    </div>
                                    <div className="info_body">
                                        <h5>{i18next.t('Company Address')}</h5>
                                        <p>{i18next.t('Baghdad - Karrada - Al-Arsat - near Al-Khudairi Mosque.')}</p>
                                        <p className='mt-2'>{i18next.t('We will be waiting for you from 9 AM to 7 PM, except on Fridays.')}</p>
                                    </div>
                                    <div className='info_body'>
                                    <div className="footer_social">
                                            <ul className="social_list">
                                                <li className="facebook"><a href='https://www.facebook.com/ALGhadeerRealEstate' target='_blank_'><i className="bi bi-facebook"></i></a></li>
                                                <li className="twitter"><a href='https://twitter.com/i/flow/login?redirect_after_login=%2Falghadeer_iq' target='_blank_'><i className="bi bi-twitter"></i></a></li>
                                                <li className="instagram"><a href='https://www.instagram.com/alghadeer_iq/' target='_blank_'><i className="bi bi-instagram"></i></a></li>
                                                <li className="youbetube"><a href='https://www.youtube.com/channel/UCiD0PWM2hPDbBXSQMHqRXqQ/featured' target='_blank_'><i className="bi bi-youtube"></i></a></li>
                                                <li className="youbetube"><a href='https://www.linkedin.com/company/al-ghadeer-%D8%A7%D9%84%D8%BA%D8%AF%D9%8A%D8%B1-%D9%84%D9%84%D8%A3%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9/about/' target='_blank_'><i className="bi bi-linkedin"></i></a></li>
                                                <li><a href='https://api.whatsapp.com/send?phone=9647806035111' target='_blank_'><i className="bi bi-whatsapp"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="footer_elements d-flex align-items-center justify-content-end">
                                    <div className="footer_elements_inner">
                                        <div className="info_body mb-4">
                                            {/* <h5>Download App on the</h5> */}
                                            <ul className="appDownload">
                                                <li><a href='https://apps.apple.com/us/app/al-ghadeer-real-estate/id1589960489' target='_blank_'><img src='/images/applestore.svg' alt='appstore'/></a></li>
                                                <li><a href='https://play.google.com/store/apps/details?id=tech.capital.algadeerrealestate' target='_blank_'><img src='/images/androidplay.svg' alt='appstore'/></a></li>
                                            </ul>
                                        </div>
                                        <div className="terms_condition">
                                            <ul>
                                                <li><a>{i18next.t('Terms')}</a></li>
                                                <li><a>{i18next.t('Condition')}</a></li>
                                                <li><a>{i18next.t('Policy')}</a></li>
                                            </ul>
                                        </div>
                                        <div className="copyright"
                                        >
                                            <p>{i18next.t('AL Ghadeer 2024. All Rights Reserved')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SectionGridLines />
                </div>
            </footer >

            <div className="totop">
                <a href="#">UP</a>
            </div>
        </>
    );
};

export default Footer;