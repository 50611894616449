import $ from 'jquery';
import React, { Fragment, useEffect } from 'react';
import FunFact from '../../components/FunFactElements/FunFact';

const FunFacts = (props) => {
    const { data } = props;

    // useEffect(() => {
    //     var a = 0;
    //     $(window).on("scroll", function () {

    //         var oTop = $('#funfacts').offset().top - window.innerHeight;
    //         if (a == 0 && $(window).scrollTop() > oTop) {
    //             $('.fun-number').each(function () {
    //                 $(this).prop('Counter', 0).animate({
    //                     Counter: $(this).text()
    //                 }, {
    //                     duration: 4000,
    //                     easing: 'swing',
    //                     step: function (now) {
    //                         $(this).text(Math.ceil(now));
    //                     }
    //                 });
    //             });
    //             a = 1;
    //         }
    //     });
    // }, []);
    
    if (!data || !data.counter) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { counter } = data;


    return (
        <section className={props.className} id="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="funfacts_inner">
                            {
                                counter.map(data =>
                                    <FunFact
                                        key={data.id}
                                        data={data}
                                    />)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FunFacts;