import React from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
const JobsCard = (props) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="blog_post mb-0" style={{height:"300px"}}>
                <div className="blog_content">
                    <div className="meta">
                        <time className="text-olive">{i18next.t("DEADLINE")} : {props.date}</time>
                    </div>
                    <h5><Link to={`/jobs-details/${props.index}`} state={props.data} className="text-white">{props.title}</Link></h5>
                    <p>{props.text}</p>
                    <div className="details_link"><Link to={`/jobs-details/${props.index}`} state={props.data}><span className="link_text">View Details</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                </div>
            </div>
        </div>
    );
};

export default JobsCard;