import React from 'react';
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';

const ContactContainer = () => {

    return (
        <main className="wrapper">
            <section className="gmap box_padding">
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        {/* <Map /> */}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3335.095920222659!2d44.42148108480475!3d33.29015348082017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDE3JzI0LjYiTiA0NMKwMjUnMDkuNSJF!5e0!3m2!1sar!2siq!4v1697904479870!5m2!1sar!2siq" width="600" height="450" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>

            <ContactForm />
        </main>
    );
};

export default ContactContainer;