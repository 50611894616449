import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard from '../../components/ProjectElements/ProjectCard';
import SectionGridLines from '../../components/SectionGridLines';
import SectionHeader from '../../components/SectionHeader';
import { Link } from "react-router-dom";
import i18next from 'i18next';

const ProjectGallery = (props) => {
    const { data } = props;

    Isotope.current = new Isotope('.grid', {
        itemSelector: '.grid-item',
        percentPosition: true,
        // layoutMode: 'packery',
        // masonry: {
        //     columnWidth: '.grid-sizer'
        // },
        transformsEnabled: true,
        transitionDuration: "700ms",
        resize: true,
        fitWidth: true,
        columnWidth: '.grid-sizer',
        options: {
            layoutMode: 'masonry',
            masonry: { columnWidth: 100 },
            transitionDuration: '0.5s'
        }
    })


    if (!data || !data.projects) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { projects } = data;

    return (
        <section className="projects packery">
            <SectionGridLines></SectionGridLines>
            <div className="large_font">
                <SectionHeader title="Gallery"></SectionHeader>
            </div>
            <div className="container">
                <div className="section-header text-center has_line">
                    <PageHeder className='text-white' title="Our Project"></PageHeder>
                    <div className="section-desc row align-items-center justify-content-center">
                        <div className="col-lg-12 text-center">
                            <Paragraph text="We will continue to grow and evolve ALGhader for the betterment of our clients and our people. Vision includes the continual expansion."></Paragraph>
                        </div>
                        {/* <div className="col-lg-6 text-start">
                            <Paragraph text="We will continue to grow and evolve ALGhader for the betterment of our clients and our people. Vision includes the continual expansion."></Paragraph>
                        </div> */}
                    </div>
                </div> 
                
                <div className="row gutter-20">
                    <div className="grid-sizer"></div>

                    {
                        projects.map((item,index) => 
                        // <ProjectCard
                        //     key={data.id}
                        //     data={data}
                        // />
                        <div className={`grid-item ${item.tag} width-50`} key={index}>
                        <div className="thumb">
                            <img className="item_image" src={`https://backend.alghadeerrealestate.com/${item.project_live_images[0].image}`} alt="" />
                            <div className="works-info">
                                <div className="label-text">
                                    <h6><Link to={`/project-details/${index}`} state={projects}>{i18next.t("AL Ghadeer")}</Link></h6>
                                    <h5><Link to={`/project-details/${index}`} state={projects}>{item.name}</Link></h5>
                                    <div className="details_link"><Link to={`/project-details/${index}`} state={projects}><span className="link_text">{i18next.t("View Details")}</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    }

                </div>
                {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
            </div>
        </section>
    );
};

export default ProjectGallery;