import JobApplyForm from "../components/JobApplyForm";
import i18next from "i18next";

const Checkout = (props) => {

    return (
            <div className="container">
                <div className="shipping_form">
                        <div className="have_coupon">
                            <div className="form-group">
                                <label for="couponcode">{i18next.t("JOB APPLICATION FORM")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <WidgetTitle title='Billing Address' className='widget-title' /> */}
                                <JobApplyForm id={props.id} />
                            </div>
                        </div>
                </div>
            </div>
    );
};

export default Checkout;