import React from 'react';
import i18next from 'i18next';

const PageHeader02 = (props) => {
    return (
        <>
            <div className="page_header">
                <div className="page_header_inner Hajen">
                    <div className="container">
                        <div className="page_header_content d-flex align-items-center justify-content-between">
                            <h2 className="heading">{i18next.t(props.title)}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-layer-bg"></div>
        </>
    );
};

export default PageHeader02;