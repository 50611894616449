import React from 'react';
import IconBoxFlex from '../../components/IconBoxFlex';
import Paragraph from '../../components/Paragraph';
import SubHeading from '../../components/SubHeading';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

const AboutContainer = (props) => {
    const { data } = props;
    if (!data || !data.about) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { about } = data;
    return (

        <section className="about bg-dark-100">
            {/* <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">About</h2>
            </div> */}
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5">
                        <div className="about_image">
                            <img src="images/about/a1.jpg" alt="img" />
                            <img src="images/about/e1.svg" alt="About" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7">
                        <div className="about_text_inner">
                            <div className="about_text">
                                <SubHeading title="ABOUT ALGHADEER"></SubHeading>
                                <Paragraph text={about[0].subtitle}></Paragraph>
                            </div>

                            {/* <div className="about_icon_box">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i2.svg" text="3D Space Designing"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i3.svg" text="Architectural Drawing"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i1.svg" text="3D Model building"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i4.svg" text="Building The Space"></IconBoxFlex>
                                    </div>
                                </div>
                            </div> */}

                            <div className="btn_group" >
                                <Link href="/about" className="btn gray">{i18next.t("Read More")}</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContainer;