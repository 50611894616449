import React from 'react';
import LazyLoad from 'react-lazyload';

const RelatedProjectCard = (props) => {
    return (
        <div className="thumb">
            <LazyLoad height={10} offset={10} once><img className="item_image" src={props.img} alt="" /></LazyLoad>
            <div className="works-info">
                <div className="label-text">
                    <h5>{props.name}</h5>
                    <h6>{props.description}</h6>
                </div>
            </div>
        </div>
    );
};

export default RelatedProjectCard;