import React , { useState , useEffect } from 'react';
import JobsContainer from '../../containers/JobsContainer';
import PageHeader02 from '../../components/PageHeader02';
import axios from 'axios';

const Jobs = () => {
    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com/jobs/${lang}/`)
        .then(response => {
            setData(response.data)
            // console.log(response.data)
        })
    },[])
    return (
        <>
            <PageHeader02 title="Our Jobs" />
            <JobsContainer data={data} />
        </>
    );
};

export default Jobs;