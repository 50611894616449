import React from 'react';

const ClientsLogo = (props) => {
    const { data } = props;
    if (!data || !data.companies) {
        // Handle the case where data or data.companies is undefined
        return <div></div>;
    }
    const { companies } = data;

    return (
        <div className="partner_flex">
            {companies.map((item, index) => {
                return (
                    <a href={item.link} target='_blank_' key={index}>
                    <div className="partner_content" key={index}>
                        <img src={`https://backend.alghadeerrealestate.com/${item.image_logo_dark}`} alt="SVG" />
                        <img src={`https://backend.alghadeerrealestate.com/${item.image_logo_light}`} alt="SVG" />
                    </div>
                    </a>
                );
            })}
        </div>
    );
};

export default ClientsLogo;
