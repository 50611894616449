import React from 'react';
import ServiceCard from '../components/ServiceCard';
import FormContainer from '../containers/FormContainer';

const ServiceContainer01 = (props) => {
    const { data } = props;
    if (!data || !data.services) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { services } = data;
    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row">
                        {services.map((item, index) => {
                            return(
                                <div className="col-lg-4" >
                                <ServiceCard number={item.id} imgDark="images/icon_box/d1.svg" imgLight="images/icon_box/d1-light.svg" title={item.title} text={item.subtitle} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            {/* <Testimoals /> */}
            <FormContainer data={data} />

        </main>
    );
};

export default ServiceContainer01;