import React from 'react';
import i18next from 'i18next';

const SideBar = (props) => {
    return (
        <div className="sidebar">
            <div className="widget widget_block">
                <div className="wp-block-group__inner-container">
                    <h2 className="widget-title">{i18next.t("Date")} <span className="title-line"></span></h2>
                    <ul className=" wp-block-archives-list wp-block-archives">
                        <li>{props.created_at}</li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default SideBar;