import React, { Fragment } from 'react';

const TestimonialSlide = (props) => {

    return (
        <Fragment>
        {props.data &&
        <div className="testimonial-block text-center">
            <p>{props.data.subtitle}</p>
            <h6 className="text-olive">{props.data.title}</h6>
        </div>
        }
        </Fragment>

    );
};

export default TestimonialSlide;