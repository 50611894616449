import React from 'react';
import i18next from 'i18next';

const AboutClientsLogo = (props) => {
    const { data } = props;
    if (!data || !data.companies) {
        // Handle the case where data or data.companies is undefined
        return <div></div>;
    }
    const { companies } = data;

    // Define an array of filter words
    const filterWords = ['Real estate developer','Executor', 'Investor', 'Donors' , 'Consulting'];  // Replace with your filter words
    
    return (
        <div className='row AboutClientLogo'>
            {filterWords.map((word, index) => {
                // Filter the companies based on the current word
                const filteredCompanies = companies.filter(item => item.company_type.includes(word));
                
                return (
                    <div key={index} className='AboutClientLogoBox col-lg-8'>
                        <h5 className='mt-4'>{word} </h5>
                        <div className="partner_flex mt-4">
                            {filteredCompanies.map((item, index) => {
                                return (
                                    <div className="partner_content me-4" key={index}>
                                        <img src={`https://backend.alghadeerrealestate.com/${item.image_logo_dark}`} alt="SVG" />
                                        <img src={`https://backend.alghadeerrealestate.com/${item.image_logo_light}`} alt="SVG" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AboutClientsLogo;
