import React, { useEffect , useState } from 'react';
import { Fragment } from 'react';

const ThemeSwitcher = () => {

    const [show , setShow] = useState(false);

    useEffect(() => {
        // Day-Night Mode Switcher
        var icon = document.getElementById("mode_switcher");
        var socialIcon = document.getElementById("Social_switcher"); 

        if (localStorage.getItem("theme") === "null") {
            localStorage.setItem("theme", "dark");
        }

        // let localData = localStorage.getItem("theme");

        // if (localData === "light") {
        //     icon.innerHTML = '<span><i class="bi bi-moon-fill"></i></span>';
        //     document.body.classList.remove("bg-white");
        // } else if (localData === "dark") {
        //     icon.innerHTML = '<span><i class="bi bi-sun-fill"></i></span>';
        //     document.body.classList.add("bg-white");
        // }

        icon.onclick = function () {
            document.body.classList.toggle("bg-white");
            if (document.body.classList.contains("bg-white")) {
                icon.innerHTML = '<span><i class="bi bi-moon-fill"></i></span>';
                localStorage.setItem("theme", "dark");
            } else {
                icon.innerHTML = '<span><i class="bi bi-sun-fill"></i></span>';
                localStorage.setItem("theme", "light");
            }
        }

        socialIcon.onclick = function() {
            setShow(true);
        }
        
    }, []);

    const handleShow = () => {
        setShow(false);
    }

    return (
        <Fragment>
        {show && 
            <div className='socialPopUp'>
            <div className="social_sites">
            <button onClick={handleShow}><i class="bi bi-x" id='popUpCross'></i></button>
                    <h1>ALGhadeer</h1>
                    <ul className="d-flex align-items-center justify-content-center mt-4">
                        <li><a href='https://www.facebook.com/ALGhadeerRealEstate' target='_blank_'><i className="bi bi-facebook"></i></a></li>
                        <li><a href='https://twitter.com/i/flow/login?redirect_after_login=%2Falghadeer_iq' target='_blank_'><i className="bi bi-twitter"></i></a></li>
                        <li><a href='https://www.instagram.com/alghadeer_iq/' target='_blank_'><i className="bi bi-instagram"></i></a></li>
                        <li><a href='https://www.youtube.com/channel/UCiD0PWM2hPDbBXSQMHqRXqQ/featured' target='_blank_'><i className="bi bi-youtube"></i></a></li>
                        <li><a href='https://www.linkedin.com/company/al-ghadeer-%D8%A7%D9%84%D8%BA%D8%AF%D9%8A%D8%B1-%D9%84%D9%84%D8%A3%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9/about/' target='_blank_'><i className="bi bi-linkedin"></i></a></li>
                        <li><a href='https://api.whatsapp.com/send?phone=9647806035111' target='_blank_'><i className="bi bi-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
        }
        <div id="mode_switcher">
            <span><i className="bi bi-sun-fill"></i></span>
        </div>
        <div id="Social_switcher">
            <span><i className="bi bi-messenger"></i></span>
        </div>
        </Fragment>
    );
};

export default ThemeSwitcher;