import React from 'react';
import i18next from 'i18next';

const PageBanner = (props) => {
    return (
        <>
            <div className="page_header">
                <div className="page_header_inner" style={{backgroundImage: props.cover ? `url(${props.cover})` : `url(/images/header_bg.jpg)`}}>
                    <div className="container">
                        <div className="page_header_content d-flex align-items-center justify-content-between">
                            <h2 className="heading">{i18next.t(props.title)}</h2>
                            <ul className="breadcrumb d-flex align-items-center">
                                <li><a href="/">{i18next.t("Home")}</a></li>
                                <li><a href="#">{i18next.t(props.pageTitle)}</a></li>
                                <li className="active">{i18next.t(props.activePage)}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageBanner;