import React from 'react';
import ClientsLogo from '../components/ClientsLogo';
import AboutClientsLogo from '../components/AboutClientsLogo';
import Form from '../components/Form';
import FormText from '../components/FormText';
import SectionGridLines from '../components/SectionGridLines';
import { useLocation } from 'react-router-dom';

const FormContainer = (props) => {
    const location = useLocation();
    return (
        <section className="contact_us bg-dark-200">
            <div className="container">
                <div className="row justify-content-between">
                    <FormText></FormText>
                    <div className="col-lg-6">
                        <Form></Form>
                    </div>
                </div>
                {location.pathname !== '/about' ?
                <ClientsLogo data={props.data}></ClientsLogo>
                : <AboutClientsLogo data={props.data}></AboutClientsLogo>
                }
            </div>
            <SectionGridLines></SectionGridLines>
        </section>
    );
};

export default FormContainer;