import React , {useState ,useEffect} from 'react';
import PageBanner from '../../containers/PageBanner';
import ProjectDetailsContainer from '../../containers/ProjectContainers/ProjectDetailsContainer';
import { useLocation, useParams } from "react-router-dom";

const ProjectDetails = () => {
    const [data , setData] = useState({});
    let { id } = useParams();
    let location = useLocation(); 

    useEffect(() => {
    setData(location.state[id])
    },[]);

    return (
        <main className="wrapper">
            <PageBanner pageTitle='Projects' title={data.name} activePage={data.name} cover={data.cover} />
            <ProjectDetailsContainer data={data}/>

        </main>
    );
};

export default ProjectDetails;