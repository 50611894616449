import React from 'react';
import SwiperCore , { EffectFade, Navigation ,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsInner from '../../components/ProjectElements/ProjectDetailsInner';
import ProjectDetailsSlider from '../../components/ProjectElements/ProjectDetailsSlider';
import SideBar02 from '../../components/SideBar02';
SwiperCore.use([Autoplay]);

const ProjectDetailsContainer = (props) => {
    const { data } = props;
    if (!data || !data.project_live_images ) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { project_live_images } = data;
    // console.log(data)
    return (
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation]}
                            effect="slide"
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 2000, // Autoplay delay in milliseconds
                                disableOnInteraction: false, // Autoplay continues even when the user interacts with the slider
                            }}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            {project_live_images.map((item , index) => {
                                return(
                                <SwiperSlide key={index}>
                                    <ProjectDetailsSlider index={index} img={`https://backend.alghadeerrealestate.com/${item.image}`} />
                                </SwiperSlide>
                                )

                            })}

                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div className="col-lg-4 col-md-4">
                        <SideBar02  data={data}/>
                    </div> */}

                    <div className="col-lg-12 col-md-12">
                        <ProjectDetailsInner data={data}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectDetailsContainer;