import React, {useState , useEffect} from 'react';
import AboutContainer from '../../containers/AboutContainers/AboutContainer';
import Blogs from '../../containers/Blogs';
import FormContainer from '../../containers/FormContainer';
import FunFacts from '../../containers/FunFacts/FunFacts';
import ProjectGallery from '../../containers/ProjectContainers/ProjectGallery';
import Services from '../../containers/Services';
import Sliders03 from '../../containers/SliderContainers/Sliders03';
import Testimonials from '../../containers/Testimonials';
import axios from 'axios';

const Home01 = () => {

    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com/homepage/homepage/${lang}/`)
        .then(response => {
            setData(response.data)
            // console.log(response.data)
        })
    },[])

    return (

        <div className="wrapper">
            <Sliders03 data={data} />
            <Services data={data}/>
            <AboutContainer data={data} />
            <FunFacts data={data} className='funfacts bg_1' />
            <ProjectGallery data={data} />
            <Testimonials data={data} /> 
            <Blogs data={data} />
            <FormContainer data={data} />
        </div>

    );
};

export default Home01;