import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionGridLines from '../components/SectionGridLines';
import TestimonialSlide from '../components/TestimonialSlide';

const Testimonials = (props) => {
    const { data } = props;
    if (!data || !data.opinion) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { opinion } = data;
    return (
        <section className="testimonial box_padding">
            <SectionGridLines />
            <div className="has_line_lg"></div>

            <div className="testimonial_inner bg-black">
                <div className="swiper swiper_testimonial">

                    <Swiper
                        modules={[Pagination]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                            speed: 5000
                        }}
                        slidesPerView={1.5}
                        loop={true}
                        spaceBetween={150}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                    >
                        {opinion.map(item => {
                            return(
                                <SwiperSlide key={item.id}>
                                <TestimonialSlide key={item.id} data={item}/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;