import React from 'react';
import JobsCard from '../components/JobsCard';
import FormContainer from '../containers/FormContainer';
import i18next from 'i18next';

const JobsContainer = (props) => {
    const { data } = props;
    if (!data) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    return (
        <main className="wrapper mt-4">
        <section className='jobsTitleSection p-0'>
            <div className='container'>
                <div className='row'>
                    <h1>{i18next.t("Be a Part of Our Professional Team")}</h1>

                    <h2>{i18next.t("About Our Team")}</h2>
                    <p>{i18next.t("At Al Ghadeer Real Estate Company, we take pride in our team of professionals, experts, and highly skilled individuals who work together like a family to provide the best possible experience for our valued customers. As we continue to grow and expand, we are always looking for exceptional talents to join our ranks.")}</p>

                    <h2>{i18next.t("Benefits and Perks")}</h2>
                    <p>{i18next.t("We are not just another real estate company. We are a fast-growing organization that fosters a culture of growth, learning, and excellence. When you join Al Ghadeer, you become a part of a dynamic team that values innovation and provides enormous opportunities for skill development and career advancement. Additionally, we offer a comprehensive benefits package and competitive compensation to reward our employees for their hard work and dedication.")}</p>

                    <h2>{i18next.t("Application Process")}</h2>
                    <p>{i18next.t("Our recruitment process is designed to find the best match for both the company and the candidate. Here is a step-by-step guide on how to apply:")}</p>
                    <ol>
                        <li>{i18next.t("Stay Informed: Keep an eye on our official website for open job listings. We regularly update our jobs page with new opportunities.")}</li>
                        <li>{i18next.t("Submit Your Application: If you find a job that matches your knowledge and skills, don't hesitate to apply. Submit your application through our user-friendly online portal.")}</li>
                        <li>{i18next.t("Application Review: We understand the importance of your time, and our recruitment team is committed to promptly reviewing all applications. You can expect to receive a response from us shortly after submitting your application.")}</li>
                        <li>{i18next.t("Interview: Congratulations! If your application meets the requirements of the vacant position, our recruitment team will contact you to schedule an interview. This is your opportunity to showcase your talents and abilities.")}</li>
                        <li>{i18next.t("Stay Informed: We appreciate every candidate who applies to Al Ghadeer. For candidates who successfully pass the interview process, we promise to keep you informed throughout the journey. If you are not selected for a particular role for any reason, don't worry. Your information will be stored in our database for future opportunities that match your profile.")}</li>
                    </ol>

                    <h2>{i18next.t("What We're Looking For")}</h2>
                    <p>{i18next.t("We are searching for creative and high-performing individuals who not only want to enhance their skills but also share our passion for providing exceptional experiences to our customers. If you are enthusiastic, forward-thinking, and ready to make an impact in the real estate industry, Al Ghadeer is the right place for you.")}</p>

                    <h2>{i18next.t("Open Positions")}</h2>
                    <p>{i18next.t("Stay tuned to our website for updates on open positions. We provide detailed information about each vacant job, including qualifications and responsibilities.")}</p>

                    <h2>{i18next.t("Ready to Join?")}</h2>
                    <p>{i18next.t("Are you ready to embark on a fulfilling journey with Al Ghadeer Real Estate Company? Join us today and be a part of our exciting growth story.")}</p>

                </div>
            </div>
        </section>
        <section className="blog">
            <div className="container">
                <div className="row">
                    {data.map((item , index) => {
                        return(
                            <JobsCard key={index} title={item.title} text={item.task.substring(0,100)} date={item.application_deadline} id={item.id} index={index} data={data} />
                        )
                    })}
                </div>
            </div>
        </section>
        <FormContainer data={data} />
        </main>
    );
};

export default JobsContainer;