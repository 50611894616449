import React from 'react';
import ServiceCard from '../components/ServiceCard';

const Services = (props) => {
    const { data } = props;
    if (!data || !data.services) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { services } = data;
    const limitedServices = services.slice(0, 3);
    return (
        <section className="services bg-dark-100 pb-0">
            <div className="container">
                <div className="row">
                    {limitedServices.map((item, index) => {
                        return(
                            <div className="col-lg-4" key={item.id}>
                            <ServiceCard key={item.id} number={item.id} imgDark="images/icon_box/d1.svg" imgLight="images/icon_box/d1-light.svg" title={item.title} text={item.subtitle} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

export default Services;