import React, { useState } from 'react';
import i18next from 'i18next';

function FAQ({ data }) {
  const [activeIndexes, setActiveIndexes] = useState([]);

  const toggleAccordion = (index) => {
    // Check if the clicked item's index is in the activeIndexes array
    if (activeIndexes.includes(index)) {
      // If it is, remove it to close the item
      setActiveIndexes(activeIndexes.filter(i => i !== index));
    } else {
      // If it's not, add it to open the item
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const filterWords = data.projects;

  return (
    <div className="faq">
      {filterWords &&
        filterWords.map((word, wordIndex) => {
          const filteredCompanies = data.q_data.filter(item =>
            item.project.name.includes(word)
          );

          return (
            <div key={wordIndex}>
              <h4 className="mt-4">{word} </h4>
              <div>
                {filteredCompanies.map((item, itemIndex) => {
                  // Use a unique identifier for each item
                  const uniqueItemIndex = `${wordIndex}-${itemIndex}`;

                  // Create a variable to check if the item is active
                  const isActive = activeIndexes.includes(uniqueItemIndex);

                  return (
                    <div
                      key={uniqueItemIndex}
                      className={`faq-item col-lg-12 ${isActive ? 'active' : ''}`}
                    >
                      <div className="faq-question" onClick={() => toggleAccordion(uniqueItemIndex)}>
                        <span>{item.question}</span>
                        <span className="icon">{isActive ? '-' : '+'}</span>
                      </div>
                      {isActive && (
                        <div className="faq-answer">
                          <p>{item.answer}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default FAQ;
