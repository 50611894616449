import React from 'react';
import Paragraph from '../components/Paragraph';
import Checkout from '../containers/Checkout';
import i18next from 'i18next';

const JobsDetailsContainer = ({data}) => {
    return (
        <main className="wrapper">
            <section className="project-details bg-dark-200">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="project-details_inner">
                                <div className="post_content">
                                    <div className="post-header">
                                        <h3 className="post-title">{data.title}</h3>
                                    </div>
                                    <div className="intro">
                                        <Paragraph text={data.task} />
                                    </div>
                                </div>
                            </div>
                            <div className="project-details_inner">
                                <div className="post_content">
                                    <div className="post-header">
                                        <h3 className="post-title">{i18next.t("Requirements")}</h3>
                                    </div>
                                    <div className="intro">
                                        <Paragraph text={data.requirements} />
                                    </div>
                                </div>
                            </div>
                            <div className="project-details_inner">
                                <div className="post_content">
                                    <div style={{color : "#fff"}}>
                                        <h6 className="post-title">{i18next.t("DEADLINE")} : {data.application_deadline}</h6>
                                    </div>
                                    <div style={{color : "#fff"}} className='mt-1'>
                                        <h6 className="post-title">{i18next.t("STATUS")} : {data.status}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <Checkout id={data.id}></Checkout>
                        </div>
                    </div>
                </div>
            </section >
        </main >
    );
};

export default JobsDetailsContainer;