import React from 'react';
import { Link } from 'react-router-dom';

const SliderCard03 = (props) => {
    return (
        <div className="swiper-slide">
            <div className="slider" style={{ backgroundImage: `url(${props.bgImg})` }}>
                <div className="container">
                    <div className="slide_content">
                        <div className="slide_content_wrapper mb-0 h-auto">
                            <div className="slide_content_inner">
                                <h4><Link to="/project-1" className="text-white text-uppercase">{props.title}</Link></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderCard03;