import React , {useState ,useEffect} from 'react';
import JobsDetailsContainer from '../../containers/JobsDetailsContainer';
import PageBanner from '../../containers/PageBanner';
import { useLocation, useParams } from "react-router-dom";

const JobsDetails = () => {
    const [data , setData] = useState({});
    let { id } = useParams();
    let location = useLocation(); 

    useEffect(() => {
    setData(location.state[id])
    },[]);

    return (
        <>
            <PageBanner pageTitle='Jobs' title={data.title} activePage={data.title}></PageBanner>
            <JobsDetailsContainer data={data}/>
        </>
    );
};

export default JobsDetails;