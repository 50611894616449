import React , { useState , useEffect } from 'react';
import Portfolio from '../../components/Portfolio';
import PageBanner03 from '../../containers/PageBanner03';
import axios from 'axios';

const Project01 = () => {
    const [data , setData] = useState([]);
    const [tof , setTof] = useState(false); 

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com/projects/${lang}/`)
        .then(response => {
            setData(response.data)
            // console.log(response.data)
            setTof(true)
        })
    },[])

    return (
        <>
            <PageBanner03 title="Projects" activePage="Projects" />
            {tof && <Portfolio text='Load More' className='btn gray' data={data} />}
        </>
    );
};

export default Project01;