import React , { useState } from 'react';
import axios from 'axios';
import i18next from 'i18next';

const JobApplyForm = (props) => {

    const [data , setData] = useState({
        fname :"",
        lname : "",
        age :"",
        gender :"",
        city :"",
        martial :"",
        phone :"",
        email :"",

    });
    const [dataImage , setDataImage] = useState({ pictures: [] });
    const [successMessage, setSuccessMessage] = useState('');

    const handlerChange = (e) => {
        // set data
        const {name , value} = e.target;
        setData((prev) => {
            return {...prev, [name] : value}
        })
    }
    const handlerChangeImages = (e) => {
        let files = e.target.files;
        setDataImage((prevState) => ({
          ...prevState,
          pictures: [...(prevState.pictures || []), ...files],
        }));
        console.log(dataImage.pictures);
    };
    const handlerSubmit = (e) => {
        e.preventDefault();

        let file1 = dataImage.pictures[0];
        let formdata = new FormData();
        formdata.append('cv', file1);
        formdata.append('full_name', data.fname);
        formdata.append('last_name', data.lname);
        formdata.append('age', data.age);
        formdata.append('gender', data.gender);
        formdata.append('residence', data.city);
        formdata.append('marital_status', data.martial);
        formdata.append('phone_number', data.phone);
        formdata.append('email', data.email);
        formdata.append('job', props.id);
        
        axios.post(`https://backend.alghadeerrealestate.com/jobs/apply/`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
            setSuccessMessage('Application submitted successfully!');
            // Wait for a moment before redirecting (you can adjust the timeout value)
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          })
          .catch(err => {
            console.log(err);
            setSuccessMessage('Application submission failed.');
          });
        
    }

    return (
        <>
        {successMessage && 
        <div className='msgboxsuc'>
            <div className="success-message">{successMessage}</div>
        </div>
        }
        <form onSubmit={handlerSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="fname">{i18next.t("Full Name *")}</label>
                        <input type="text" className="form-control" id="fname" name="fname" placeholder={i18next.t("First Name")} onChange={handlerChange}   />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="lname">{i18next.t("Family Name *")}</label>
                        <input type="text" className="form-control" id="lname" name="lname" placeholder={i18next.t("Family Name")} onChange={handlerChange}   />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="address">{i18next.t("Age *")}</label>
                <input type="number" className="form-control" id="age" name="age" placeholder={i18next.t("your age")} onChange={handlerChange}   />
            </div>
            <div className="form-group">
                <label htmlFor="country">{i18next.t("Gender *")}</label>
                <select className="basic_select" id="gender" name='gender' onChange={handlerChange}  >
                    <option disabled selected>{i18next.t("select")}</option>
                    <option value="Male">{i18next.t("Male")}</option>
                    <option value="Female">{i18next.t("Female")}</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="city">{i18next.t("Living *")}</label>
                <select className="basic_select" id="city" name='city' onChange={handlerChange}  >
                    <option disabled selected>{i18next.t("select")}</option>
                    <option value="كرخ">{i18next.t("Karkh")}</option>
                    <option value="رصافة">{i18next.t("Rusafa")}</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="martial">{i18next.t("Marital Status *")}</label>
                <select className="basic_select" id="martial" name='martial' onChange={handlerChange}  >
                    <option disabled selected>{i18next.t("select")}</option>
                    <option value="أعزب">{i18next.t("Single")}</option>
                    <option value="متزوج">{i18next.t("Married")}</option>
                    <option value="مطلق">{i18next.t("Divorced")}</option>
                    <option value="أرمل">{i18next.t("Widowed")}</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="phone">{i18next.t("Phone")}</label>
                <input type="number" className="form-control" id="phone" name="phone" placeholder={i18next.t("your phone")} onChange={handlerChange}  />
            </div>
            <div className="form-group">
                <label htmlFor="email">{i18next.t("Email")}</label>
                <input type="email" className="form-control" id="email" name="email" placeholder={i18next.t("your email")} onChange={handlerChange}  />
            </div>
            <div className="form-group">
                <label htmlFor="cv">{i18next.t("CV - RESUME ( PDF only )")}</label>
                <input type="file" className="form-control" id="cv" name="cv" placeholder={i18next.t("your cv")} accept="application/pdf" onChange={handlerChangeImages}   />
            </div>

            <div className="additional_info mt-5">
                <h4 className="widget-title">{i18next.t("APPLY HERE !")}APPLY HERE !<span className="title-line"></span></h4>
                <div className="btn_group">
                  <button type="submit" className="btn olive">{i18next.t("APPLY")}</button>
                </div>
            </div>
        </form>
        </>
    );
};

export default JobApplyForm;