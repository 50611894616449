import React from 'react';
import i18next from 'i18next';

const MissionContainer = (props) => {
    const { data } = props;
    if (!data || !data.about) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { about } = data;
    return (
        <section className="mission">
            <div className="container">
                <div className="mission_top_part">
                    <div className="section-header text-center">
                        <h6 className="text-white text-uppercase">{i18next.t("Our MISSION")}</h6>
                        <p className="text-gray-600">{i18next.t("Our organizational sprit believes that where design meets passion & love with space, good architecture happens here")}</p>
                    </div>
                    <div className="has_line"></div>
                    <img src="images/bg/about_bg.jpg" alt="" />
                </div>

                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 pe-lg-4">
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line">{about[0].title}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="mission_content">
                                <p>{about[0].subtitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionContainer;