import React, {useState , useEffect} from 'react';
import PageHeader02 from '../../components/PageHeader02';
import ServiceContainer01 from '../../containers/ServiceContainer';
import axios from 'axios';

const Service01 = () => {
    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com//homepage/homepage/${lang}/`)
        .then(response => {
            setData(response.data)
            // console.log(response.data)
        })
    },{})
    return (
        <>
            <PageHeader02 title="Our Services" />
            <ServiceContainer01 data={data} />
        </>
    );
};

export default Service01;