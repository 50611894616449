import React from 'react';
import RelatedProjects from './RelatedProjects';
import FunFacts from '../../containers/FunFacts/FunFacts';
import i18next from 'i18next';

const ProjectDetailsInner = (props) => {
    const { data } = props;
    if (!data) {
        // Handle the case where data or data.services is undefined
        return <div>Data or services are undefined</div>;
    }
    const { name , brief_description , project_highlights , area_description , area_features } = data;
    return (
        <div className="project-details_inner">
            <div className="post_content">
                <div className="post-header">
                    <h3 className="post-title">{name}</h3>
                </div>
                <div className="fulltext">
                    <p>
                        {brief_description}
                    </p>
                    {/*  */}
                    <FunFacts data={data} className='funfacts bg_1' />
                    
                    <h6 className="widget-title">
                        {i18next.t("Project Highlights")}
                        <span className="title-line"></span>
                    </h6>
                    <p>{project_highlights}</p>
                    {/* <h6 className="widget-title">
                        {i18next.t("Area Description")}
                        <span className="title-line"></span>
                    </h6>
                    <p>
                        {area_description}
                    </p> */}
                    <h6 className="widget-title">
                        {i18next.t("Area Feature")}
                        <span className="title-line"></span>
                    </h6>
                    <p>
                        {area_features}
                    </p>

                    {/* <div className="post-footer">
                        <nav className="d-flex align-items-center justify-content-between">
                            <div className="details_link l-dir pagination-previous"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                            <div className="page-all"><a href="#"><i className="bi bi-grid"></i></a></div>
                            <div className="details_link r-dir pagination-next"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                        </nav>
                    </div> */}
                </div>
                <RelatedProjects data={data.houses}/>
            </div>
        </div>
    );
};

export default ProjectDetailsInner;