import React from 'react';
import BlockGallery from '../components/BlockGallery';
import Paragraph from '../components/Paragraph';
import PointOrder from '../components/PointOrder';
import SideBar from '../components/SideBar';
import WidgetTitle from '../components/WidgetTitle';

const BlogDetailsContainer = ({data}) => {
    return (
        <main className="wrapper">
            <section className="project-details bg-dark-200">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="project-details_inner">
                                <div className="post_content">
                                    <div className="post-header">
                                        <h3 className="post-title">{data.title}</h3>
                                    </div>
                                    <div className="intro">
                                        <Paragraph text={data.subtitle} />
                                    </div>
                                    <div className="fulltext">
                                        <BlockGallery className='block-gallery mb-0' img={`https://backend.alghadeerrealestate.com/${data.image}`} />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <SideBar created_at={data.created_at}></SideBar>
                        </div>
                    </div>
                </div>
            </section >
        </main >
    );
};

export default BlogDetailsContainer;