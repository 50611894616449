import React , {useState ,useEffect} from 'react';
import BlogDetailsContainer from '../../containers/BlogDetailsContainer';
import PageBanner from '../../containers/PageBanner';
import { useLocation, useParams } from "react-router-dom";

const BlogDetails = () => {
    const [data , setData] = useState({});
    let { id } = useParams();
    let location = useLocation(); 

    useEffect(() => {
    setData(location.state[id])
    },[]);

    return (
        <>
            <PageBanner pageTitle='Pages' title="News Details" activePage="News-Details"></PageBanner>
            <BlogDetailsContainer data={data} />
        </>
    );
};

export default BlogDetails;