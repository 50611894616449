import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import ProjectCard from '../components/ProjectElements/ProjectCard';
import SectionGridLines from '../components/SectionGridLines';
import { Link } from "react-router-dom";
import i18next from 'i18next';

const Portfolio = (props) => {
    const { data } = props;

    // const [filterKey, setFilterKey] = useState('*')

    Isotope.current = new Isotope('.grid', {
        itemSelector: '.grid-item',
        percentPosition: true,
        transformsEnabled: true,
        transitionDuration: "700ms",
        resize: true,
        fitWidth: true,
        columnWidth: '.grid-sizer',
    })

    // cleanup


    if (!data) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }

    // // handling filter key change
    // useEffect(() => {
    //     filterKey === '*'
    //         ? Isotope.current.arrange({ filter: `*` })
    //         : Isotope.current.arrange({ filter: `.${filterKey}` })
    // }, [filterKey]);

    // // const handleFilterKeyChange = key => () => setFilterKey(key)

    return (
        <section className="projects packery">
            <SectionGridLines />
            <div className="container">

                {/* <div className="portfolio-filters-content">
                    <div className="filters-button-group">
                        <button className="button" onClick={handleFilterKeyChange('*')}>All <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('exterior')}>Exterior <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('residences')}>Residences <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('interiors')}>Interiors <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('landscape')}>Landscape <sup className="filter-count"></sup></button>
                    </div>
                </div> */}
                <div className="row gutter-20">
                    <div className="grid-sizer"></div>

                    {
                        data.map((item,index) => 
                        // <ProjectCard
                        //     key={data.id}
                        //     data={data}
                        // />
                        <div className={`grid-item ${item.tag} width-50`} key={index}>
                        <div className="thumb">
                            <img className="item_image" src={`https://backend.alghadeerrealestate.com/${item.project_live_images[0].image}`} alt="" />
                            <div className="works-info">
                                <div className="label-text">
                                    <h6><Link to={`/project-details/${index}`} state={data}>{i18next.t("AL Ghadeer")}</Link></h6>
                                    <h5><Link to={`/project-details/${index}`} state={data}>{item.name}</Link></h5>
                                    <div className="details_link"><Link to={`/project-details/${index}`} state={data}><span className="link_text">{i18next.t("View Details")}</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                        )
                    }

                </div>
                {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
            </div>
        </section>
    );
};

export default Portfolio;