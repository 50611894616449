import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

const UpperHeader = () => {
    // lang
    const changeLang = (e) => {
        localStorage.setItem('lang',e.target.value);
        window.location.href = '/';
    }
    const lang = localStorage.getItem('lang') || 'en';
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header .container').classList.add('.top-up');
        } else {
            document.querySelector('header .container').classList.remove('.top-up');
        }
    }

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        // Aside info bar
        $('.aside_open').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').addClass('show');
        });
        $('.aside_close').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').removeClass('show');
        });

        // Toggle Header Search
        $('.header_search .form-control-submit').click(function (e) {
            $('.open_search').toggleClass('active');
        });

        // Sticky Header
        var header = $("header");
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 50) {
                header.addClass("bg-dark sticky");
            } else {
                header.removeClass("bg-dark sticky");
            }
        });
    }, []);

    return (
        <header className="header">
            <div className="container">
                <div className="header_inner d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <a href='/' className="light_logo"><img src="images/lightlogo.svg" alt="logo" /></a>
                        <a href='/' className="dark_logo"><img src="images/darklogo.svg" alt="logo" /></a>
                    </div>


                    <div className="mainnav d-none d-lg-block">
                        <ul className="main_menu">
                            <li className="menu-item"><NavLink to="/">{i18next.t('Home')}</NavLink></li>
                            <li className="menu-item menu-item-has-children"><NavLink to="/about">{i18next.t('About Us')}</NavLink>
                                <ul className="sub-menu">
                                    <li className="menu-item"><NavLink to="/about">{i18next.t('About Us')}</NavLink></li>
                                    <li className="menu-item"><NavLink to="/service-1">{i18next.t('Services')}</NavLink></li>
                                    <li className="menu-item"><NavLink to="/faq">{i18next.t('FAQ')}</NavLink></li>
                                </ul>
                            </li>
                            <li className="menu-item"></li>
                            <li className="menu-item"><NavLink to="/project-1">{i18next.t('Projects')}</NavLink></li>
                            <li className="menu-item"><NavLink to="/jobs">{i18next.t('Jobs')}</NavLink></li>
                            <li className="menu-item"><NavLink to="/blog">{i18next.t('News')}</NavLink></li>
                            <li className="menu-item"><NavLink to="/contact">{i18next.t('Contacts')}</NavLink></li>
                            <li className="menu-item">
                            <select className="nav-item selectI18n" onChange={changeLang} value={lang}>
                                <option value="en">En</option>
                                <option value="ar">العربية</option>
                            </select>
                            </li>
                        </ul>
                    </div>
                    <div className="header_right_part d-flex align-items-center">
                        <button className="aside_open">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button>


                        {/* Mobile Responsive Menu Toggle Button */}
                        <button type="button" className="mr_menu_toggle d-lg-none">
                            <i className="bi bi-list"></i>
                        </button>

                    </div>
                </div>
            </div>
        </header>
    );
};

export default UpperHeader;