import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import AboutUs from "../Pages/AboutUs";
import Faq from "../Pages/faq";
import Blog from "../Pages/BlogPages/Blog";
import BlogDetails from "../Pages/BlogPages/BlogDetails";
import Jobs from "../Pages/JobsPages/Jobs";
import JobsDetails from "../Pages/JobsPages/JobsDetails";
import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
import Project01 from "../Pages/ProjectPages/Project01";
import ProjectDetails from "../Pages/ProjectPages/ProjectDetails";
import Service01 from "../Pages/ServicePages/Service01";
import ScrollToTop from "../components/ScrollToTop";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>,
            },
            {
                path: '/project-1',
                element: <Project01/>,
            },
            {
                path: '/project-details/:id',
                element: <ProjectDetails/>,
            },
            {
                path: '/service-1',
                element: <Service01/>,
            },
            {
                path: '/contact',
                element: <Contact/>,
            },
            {
                path: '/about',
                element: <AboutUs/>,
            },
            {
                path: '/faq',
                element: <Faq/>,
            },
            {
                path: '/jobs',
                element: <Jobs/>,
            },
            {
                path: '/jobs-details/:id',
                element: <JobsDetails/>,
            },
            {
                path: '/blog',
                element: <Blog/>,
            },
            {
                path: '/blog-details/:id',
                element: <BlogDetails/>,
            },
            {
                path: '*',
                element: <ErrorPage/>,
            },

        ]
    },
])