import React from 'react';
import BlogCard from '../components/BlogCard';
import PaginationDiv from '../components/PaginationDiv';

const BlogsContainer = ({data}) => {
    return (
        <section className="blog">
            <div className="container">
                <div className="row">
                    {data.map((item , index) => {
                        return(
                            <BlogCard key={index} img={`https://backend.alghadeerrealestate.com/${item.image}`} title={item.title} text={item.subtitle.substring(0,200)} date={item.created_at} postCount={data.id} index={index} data={data} />
                        )
                    })}

                </div>
                {/* <PaginationDiv /> */}
            </div>
        </section>
    );
};

export default BlogsContainer;