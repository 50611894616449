import Isotope from 'isotope-layout';
import React, { useEffect } from 'react';
import RelatedProjectCard from './RelatedProjectCard';
import i18next from 'i18next';

const RelatedProjects = (props) => {
    const { data } = props;

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            // layoutMode: 'packery',
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy();

    }, []);

    if (!data) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }

    return (
        <div className="related-posts">
            <h6 className="widget-title mb-2">
                {i18next.t("HOUSES TYPES")}
                <span className="title-line"></span>
            </h6>
            <div className="grid grid-3 gutter-15 clearfix">
                <div className="grid-sizer"></div>
                {data.map(item => {
                    return(
                        <div className="grid-item residences" key={item.id}>
                            <RelatedProjectCard img='images/portfolio/4.jpg' name={item.name} description={item.description}/>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default RelatedProjects;