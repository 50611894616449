import React , {useEffect , useState} from 'react';
import FormContainer from "../containers/FormContainer";
import FunFacts from '../containers/FunFacts/FunFacts';
import MissionContainer from "../containers/MissionContainer";
import Testimonials from '../containers/Testimonials';
import TextAbout from './TextAbout';
import axios from 'axios';

const Faq = () => {

    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`https://backend.alghadeerrealestate.com/homepage/homepage/${lang}/`)
        .then(response => {
            setData(response.data)
            // console.log(response.data)
        })
    },{})

    return (
        <main className="wrapper">
            <TextAbout />
            <FormContainer data={data} />
        </main>
    );
};

export default Faq;