import React from 'react';
import i18next from 'i18next';

const AsideInfo = () => {
    return (
        <div className="aside_info_wrapper">
            <button className="aside_close"><i className="bi bi-x-lg"></i></button>
            <div className="aside_logo">
                <a href="/" className="light_logo"><img src="images/lightlogo.svg" alt="logo" /></a>
                <a href="/" className="dark_logo"><img src="images/darklogo.svg" alt="logo" /></a>
            </div >
            <div className="aside_info_inner">
                <p>{i18next.t('AL GHADEER')}</p>

                <div className="aside_info_inner_box">
                    <h5>{i18next.t('Contact Info')}</h5>
                    <p>6035</p>
                    <p>info@alghadeerrealestate.com</p>
                    <h5>{i18next.t('Office Address')}</h5>
                    <p>{i18next.t('Baghdad - Karrada - Al-Arsat - near Al-Khudairi Mosque.')}</p>
                </div>
                <div className="social_sites">
                    <ul className="d-flex align-items-center justify-content-center">
                        <li><a href='https://www.facebook.com/ALGhadeerRealEstate' target='_blank_'><i className="bi bi-facebook"></i></a></li>
                        <li><a href='https://twitter.com/i/flow/login?redirect_after_login=%2Falghadeer_iq' target='_blank_'><i className="bi bi-twitter"></i></a></li>
                        <li><a href='https://www.instagram.com/alghadeer_iq/' target='_blank_'><i className="bi bi-instagram"></i></a></li>
                        <li><a href='https://www.youtube.com/channel/UCiD0PWM2hPDbBXSQMHqRXqQ/featured' target='_blank_'><i className="bi bi-youtube"></i></a></li>
                        <li><a href='https://www.linkedin.com/company/al-ghadeer-%D8%A7%D9%84%D8%BA%D8%AF%D9%8A%D8%B1-%D9%84%D9%84%D8%A3%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9/about/' target='_blank_'><i className="bi bi-linkedin"></i></a></li>
                        <li><a href='https://api.whatsapp.com/send?phone=9647806035111' target='_blank_'><i className="bi bi-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
        </div >

    );
};

export default AsideInfo;